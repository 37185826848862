import {NgModule} from '@angular/core';

import {KbStoreModule} from "@synisys/skynet-store-kb-impl";
import {KbService} from "./api/kb.service";
import {KbHttpService} from "./http/kb-http.service";

@NgModule({
              imports  : [
                  KbStoreModule
              ],
              providers: [{provide: KbService, useClass: KbHttpService}],
          })
export class KbServiceModule {
}
