import {MetaCategoryId} from "./meta-category-id";
import {MetaFieldId} from "./meta-field-id";
import {MultilingualString} from "@synisys/idm-crosscutting-concepts-frontend";

export class MetaCategory {
    constructor(private metaCategoryId: MetaCategoryId,
                private displayNameMsgId: string,
                private hasCustomSorting: boolean,
                private hasIcon?: boolean,
                private defaultIconPath?: string,
                private wfProcessId?: number,
                private isCacheable?: boolean,
                private nameMetaFieldIds?: Array<MetaFieldId>,
                private isWithVersioning?: boolean,
                private isClassifier?: boolean,
                private keyMetaFields?: Array<MetaFieldId>,
                private actionDataMetaFieldIds?: Array<MetaFieldId>,
                private transientMetaFieldIds?: Array<MetaFieldId>,
                private displayNameMultilingual?: MultilingualString,
                private showInCategoryManager?: boolean,
                private searchableFieldIds?: Array<MetaFieldId>) {
    }

    public getSystemName(): string {
        return this.metaCategoryId.getSystemName();
    }

    public getMetaCategoryId(): MetaCategoryId {
        return this.metaCategoryId;
    }

    public getHasCustomSorting(): boolean {
        return this.hasCustomSorting;
    }

    public getDisplayNameMsgId(): string {
        return this.displayNameMsgId;
    }

    public getHasIcon(): boolean {
        return this.hasIcon;
    }

    public getDefaultIconPath(): string {
        return this.defaultIconPath;
    }

    public getWFProcessId(): number {
        return this.wfProcessId;
    }

    public getNameMetaFieldIds(): Array<MetaFieldId> {
        return this.nameMetaFieldIds;
    }

    public getIsCacheable(): boolean {
        return this.isCacheable;
    }

    public getIsWithVersioning(): boolean {
        return this.isWithVersioning;
    }

    public getIsClassifier(): boolean {
        return this.isClassifier;
    }

    public getKeyMetaFieldIds(): Array<MetaFieldId> {
        return this.keyMetaFields;
    }

    public getActionDataMetaFieldIds(): Array<MetaFieldId> {
        return this.actionDataMetaFieldIds;
    }

    public getTransientMetaFieldIds(): Array<MetaFieldId> {
        return this.transientMetaFieldIds;
    }

    public getDisplayNameMultilingual(): MultilingualString {
        return this.displayNameMultilingual;
    }

    public getShowInCategoryManager(): boolean {
        return this.showInCategoryManager;
    }

    public getSearchableFieldIds(): MetaFieldId[] {
        return this.searchableFieldIds;
    }

}

