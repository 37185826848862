import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {MultilingualString} from '@synisys/idm-crosscutting-concepts-frontend';
import {LanguagesState} from '@synisys/skynet-store-languages-api';
import {
    MessageKeyFactory,
    messageStoreManager,
} from '@synisys/skynet-store-messages-api';
import {notNil} from '@synisys/skynet-store-utilities';
import {isNil, isString} from 'lodash';
import {Observable} from 'rxjs/Observable';
import {of} from 'rxjs/observable/of';
import {map, switchMap} from 'rxjs/operators';
import {createLogger} from '../utilities';

const logger = createLogger('translation-service');

@Injectable()
export class TranslationService {
    private readonly currentLanguageId$ = this.store.select(
        state => state.languages.currentLanguageId
    );

    constructor(private readonly store: Store<LanguagesState>) {}

    public translate(
        name: string,
        defaultValue: string = '',
        replaceTo: string[] = []
    ): Observable<string> {
        return this.currentLanguageId$.pipe(
            switchMap(languageId => {
                if (isNil(languageId)) {
                    logger.error(`Current language is ${languageId}`);
                    return of(defaultValue);
                }
                return messageStoreManager
                    .selectOne(
                        this.store,
                        MessageKeyFactory({name, languageId})
                    )
                    .pipe(map(msg => (msg ? msg.value : defaultValue)));
            }),
            map(message =>
                message
                    ? replaceTo.reduce(
                          (previousValue, currentValue, i) =>
                              previousValue.replace(`{${i}}`, currentValue),
                          message
                      )
                    : ''
            )
        );
    }

    public translateMultilingual(
        multilingual:
            | MultilingualString
            | {[key: string]: string}
            | string
            | undefined,
        defaultValue: string = ''
    ): Observable<string> {
        if (isNil(multilingual)) {
            return of(defaultValue);
        }
        if (isString(multilingual)) {
            return of(multilingual);
        }
        return this.currentLanguageId$.pipe(
            map(language => {
                if (isNil(language)) {
                    logger.error(`Current language is ${language}`);
                    return defaultValue;
                } else if (multilingual instanceof MultilingualString) {
                    return multilingual.getValue(language);
                } else if (notNil(multilingual[language])) {
                    return multilingual[language];
                } else {
                    return defaultValue;
                }
            })
        );
    }
}
