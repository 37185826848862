import {ChangeDetectorRef, NgZone} from '@angular/core';
import {ScrollableElement} from './scrollable-element';

export class Table implements ScrollableElement {
    public headerFixed = false;
    public footerFixed = false;
    public footerTransition = 0;
    public headerTransition = 0;
    private readonly tableBottom: number;
    private readonly bottomToHeadDistance: number;

    constructor(
        public readonly tableTop: number,
        private readonly tableHeight: number,
        public readonly headerHeight: number,
        private readonly footerHeight,
        private readonly pageHeaderHeight: number,
        public readonly ngZone: NgZone,
        private readonly cd: ChangeDetectorRef
    ) {
        this.tableBottom = tableHeight + tableTop;
        this.bottomToHeadDistance = tableHeight - footerHeight;
    }

    public get relativeTableTop(): number {
        return this.tableTop - this.pageHeaderHeight;
    }

    public get correctedTableBottom(): number {
        return this.tableBottom - this.pageHeaderHeight;
    }

    private get effectiveTableHeadHeight(): number {
        return this.headerHeight + this.headerTransition;
    }

    public scroll(scrollBy: number, scrollTop: number): void {
        this.moveHeaderIfNecessary(scrollBy, scrollTop);
        this.fixOrUnfixHeader(scrollTop, scrollBy);
        this.fixOrUnfixFooter(scrollTop);
    }

    private moveHeaderIfNecessary(scrollBy: number, scrollTop: number): void {
        if (this.headerFixed) {
            if (
                this.headerTransition <= 0 &&
                scrollBy > 0 &&
                !this.isTableInScrollContext(scrollTop)
            ) {
                const correctedTransition = this.correctHeaderNegativeTransition(
                    this.headerTransition - scrollBy
                );
                this.setNewValue('headerTransition', correctedTransition);
            } else if (
                (this.headerTransition < this.pageHeaderHeight &&
                    this.headerTransition > 0) ||
                (this.headerTransition === this.pageHeaderHeight &&
                    scrollBy > 0) ||
                (this.headerTransition === 0 && scrollBy < 0)
            ) {
                const correctedTransition = this.correctHeaderPositiveTransition(
                    this.headerTransition - scrollBy
                );
                this.setNewValue('headerTransition', correctedTransition);
            } else if (scrollBy < 0) {
                this.setNewValue(
                    'headerTransition',
                    Math.min(
                        this.headerTransition - scrollBy,
                        this.pageHeaderHeight
                    )
                );
            }
        }
    }

    private correctHeaderPositiveTransition(transition: number): number {
        transition = Math.min(transition, this.pageHeaderHeight);
        transition = Math.max(transition, 0);
        return transition;
    }

    private correctHeaderNegativeTransition(transition: number): number {
        transition = Math.max(transition, -this.headerHeight);
        transition = Math.min(transition, 0);
        return transition;
    }

    private currentFooterTop(scrollTop: number): number {
        return this.currentFooterBottom(scrollTop) - this.footerHeight;
    }

    private currentFooterBottom(scrollTop: number): number {
        return this.tableBottom - scrollTop;
    }

    private fixOrUnfixHeader(scrollTop: number, scrollBy: number): void {
        if (
            !this.headerFixed &&
            scrollTop > this.relativeTableTop &&
            this.isTableInScrollContext(scrollTop)
        ) {
            this.setNewValue('headerFixed', true);
            this.setNewValue(
                'headerTransition',
                this.calculateNecessaryTransitionForFixing(scrollTop)
            );
        } else if (scrollTop <= this.relativeTableTop && this.headerFixed) {
            this.setNewValue('headerFixed', false);
            this.setNewValue('headerTransition', 0);
        } else if (
            this.headerFixed &&
            !this.isTableInScrollContext(scrollTop)
        ) {
            this.setNewValue('headerFixed', false);
            this.setNewValue('headerTransition', 0);
        }
    }

    private isTableInScrollContext(scrollTop: number): boolean {
        return (
            scrollTop <=
            this.bottomToHeadDistance +
                this.relativeTableTop -
                this.effectiveTableHeadHeight
        );
    }

    private calculateNecessaryTransitionForFixing(scrollTop: number): number {
        let transition = this.correctHeaderPositiveTransition(
            scrollTop -
                (this.bottomToHeadDistance +
                    this.relativeTableTop -
                    this.headerHeight)
        );
        transition = transition < 0 ? 0 : transition;
        return transition;
    }

    private fixOrUnfixFooter(scrollTop: number): void {
        if (
            window.innerHeight < this.currentFooterBottom(scrollTop) &&
            !this.footerFixed
        ) {
            this.setNewValue('footerFixed', true);
        } else if (
            window.innerHeight >= this.currentFooterBottom(scrollTop) &&
            this.footerFixed
        ) {
            this.setNewValue('footerFixed', false);
        }
    }

    private setNewValue(
        fieldName: 'headerFixed' | 'headerTransition' | 'footerFixed',
        newValue: number | boolean
    ): void {
        this[fieldName] = this.ngZone.run(() => {
            const oldValue = this[fieldName];
            const changed = oldValue !== newValue;
            this[fieldName] = newValue;
            if (changed) {
                this.cd.detectChanges();
            }
            return newValue;
        });
    }
}
