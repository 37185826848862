import {MetaField} from "./meta-field";
import {MetaGroupId} from "./meta-group-id";
import {MultilingualString} from "@synisys/idm-crosscutting-concepts-frontend";

export class MetaGroup {
    constructor(private systemName: string,
                private displayName: string,
                private metaFields: Array<MetaField>,
                private displayNameMultilingual?: MultilingualString) {
    }

    public getMetaGroupId() {
        return new MetaGroupId(this.systemName);
    }

    public getDisplayName() {
        return this.displayName;
    }

    public getSystemName() {
        return this.systemName;
    }

    public getMetaFields(): Array<MetaField> {
        return this.metaFields;
    }


    getDisplayNameMultilingual(): MultilingualString {
        return this.displayNameMultilingual;
    }
}
