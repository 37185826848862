import {ChangeDetectorRef, NgZone} from '@angular/core';
import {notNil} from '@synisys/skynet-store-utilities';
import {Map} from 'immutable';
import * as raf from 'raf';
import {createLogger} from '../utilities';
import {PageHeader} from './page-header';
import {Table} from './table';
import {isNil} from 'lodash';
import {ResizedEvent} from 'angular-resize-event';

const LOGGER = createLogger('scrolling-stage');

export class ScrollingStage {
    private tables: Map<string, Table> = Map();
    private previousScrollTop = 0;
    private readonly _pageHeader: PageHeader;
    private readonly _ngZone: NgZone;
    private readonly _cd: ChangeDetectorRef;

    public constructor(
        ngZone: NgZone,
        cd: ChangeDetectorRef,
        headerHeight?: number
    ) {
        this._ngZone = ngZone;
        this._cd = cd;
        this._pageHeader = isNil(headerHeight)
            ? PageHeader.EMPTY
            : new PageHeader(headerHeight, ngZone, cd);
    }

    public initScrolling(scrollSideEffect?: (event: UIEvent) => void): void {
        this._ngZone.runOutsideAngular(() => {
            const elem = this.getScrollingElement();
            if (isNil(elem)) {
                LOGGER.warn('cannot find scrolling-stage');
                return;
            }
            elem.addEventListener(
                'scroll',
                ev => {
                    if (scrollSideEffect) {
                        scrollSideEffect(ev as UIEvent);
                    }
                    this.scrollListener(ev);
                },
                true
            );
        });
    }

    public handleTableResize(
        tableKey: string,
        event: ResizedEvent,
        headerHeight: number,
        footerHeight: number
    ): void {
        const oldTable = this.tables.get(tableKey);
        const newFixable = event.element.nativeElement.getBoundingClientRect();

        this.tables = this.tables.set(
            tableKey,
            new Table(
                oldTable ? oldTable.tableTop : newFixable.top,
                newFixable.height,
                headerHeight,
                footerHeight,
                this._pageHeader.height,
                this._ngZone,
                this._cd
            )
        );
    }

    public getTable(tableKey: string): Table | undefined {
        return this.tables.get(tableKey);
    }

    public destroyScrolling(): void {
        const elem = this.getScrollingElement();
        if (isNil(elem)) {
            LOGGER.warn('cannot find scrolling-stage');
            return;
        }
        elem.removeEventListener('scroll', ev => this.scrollListener(ev));
    }

    public destroyScrollingIfExists(): void {
        const elem = this.getScrollingElement();
        if (notNil(elem)) {
            elem.removeEventListener('scroll', ev => this.scrollListener(ev));
        }
    }

    public get pageHeader(): PageHeader {
        return this._pageHeader;
    }

    private scrollListener(event): void {
        if (!event.target.className.startsWith('resize-sensor')) {
            let scrollTop = event.target.scrollTop;
            const scrollBy = scrollTop - this.previousScrollTop;
            this.previousScrollTop = scrollTop;
            raf(() => {
                this._pageHeader.scroll(scrollBy);
                scrollTop +=
                    this._pageHeader.height + this._pageHeader.transition;
                this.tables.forEach(value => value.scroll(scrollBy, scrollTop));
            });
        }
    }

    private getScrollingElement(): Element {
        return document.getElementsByClassName('scrolling-stage').item(0);
    }
}
