import {MetaCategoryId} from "./meta-category-id";
import {MetaGroupId} from "./meta-group-id";

/**
 * Created by Tigran.Mkrtchyan on 5/25/2017.
 */
export class MetaFieldId {

    constructor(private metaCategoryId: MetaCategoryId, private systemName: string, private metaGroupId ?: MetaGroupId) {
    }

    getSystemName(): string {
        return this.systemName;
    }

    getMetaCategoryId(): MetaCategoryId {
        return this.metaCategoryId;
    }

    getMetaGroupId(): MetaGroupId {
        return this.metaGroupId;
    }
}