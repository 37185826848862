import {Pipe, PipeTransform} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {FormattingService} from '../services';
import {of} from 'rxjs/observable/of';

interface IDateFormat {
    date: string;
    dateTime: string;
    time: string;
}

@Pipe({
    name: 'sysLocalDate',
    pure: true,
})
export class LocalDatePipe implements PipeTransform {
    private format: IDateFormat = {
        date: 'date',
        dateTime: 'dateTime',
        time: 'time',
    };

    constructor(private localeService: FormattingService) {}

    public transform(
        date: number,
        format: string = 'date'
    ): Observable<string> {
        switch (format) {
            case this.format.date:
                return this.localeService.formatDate(date);
            case this.format.dateTime:
                return this.localeService.formatDateTime(date);
            case this.format.time:
                return this.localeService.formatTime(date);
        }
        return of('');
    }
}
