/**
 * @author Vahagn Lazyan.
 * @since 1.1.0
 */
import {map, mergeMap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

import {ReplaySubject} from 'rxjs/ReplaySubject';

import {FormResolverService} from './form-resolver.service';

/**
 * Extension of {@link FormResolverService} based on json.
 * Provides urls, mapped in routes.json file.
 *
 * @class
 * @extends FormResolverService
 */
@Injectable()
export class JsonBasedFormResolverService extends FormResolverService {

  protected appRoutingConfigs = 'app/dummy/routes.json';

  private appRoutesReplaySubject: ReplaySubject<RoutesModel> = new ReplaySubject<RoutesModel>(1);

  constructor(private http: HttpClient,
              router: Router) {
    super(router);
    this.http.get<RoutesModel>(this.appRoutingConfigs)
      .toPromise()
      .then((model: RoutesModel) => {
        this.appRoutesReplaySubject.next(model);
        this.appRoutesReplaySubject.complete();
      });
  }

  /**
   * Navigates to create new dynamic data-entry form.
   *
   * Returns a promise that:
   * - resolves to 'true' when navigation succeeds,
   * - resolves to 'false' when navigation fails,
   * - is rejected when an error happens.
   *
   * @param {string} categorySystemName
   * @param {string} formName
   * @returns {Promise<boolean>}
   */
  public navigateToCreateNewDeForm(categorySystemName: string, formName: string): Promise<boolean> {
    return this.navigateToDynamicDeForm(categorySystemName, formName);
  }

  /**
   * Navigates to editable dynamic data-entry form.
   *
   * Returns a promise that:
   * - resolves to 'true' when navigation succeeds,
   * - resolves to 'false' when navigation fails,
   * - is rejected when an error happens.
   *
   * @param {string} categorySystemName
   * @param {string} formName
   * @param {number} instanceId
   * @param {number} formId
   * @param {object} queryParams
   * @param {string} tabId
   * @returns {Promise<boolean>}
   */
  public navigateToEditDeForm(categorySystemName: string, formName: string, instanceId: number,
                              formId?: number, queryParams?: object, tabId?: string): Promise<boolean> {
    return this.navigateToDynamicDeForm(categorySystemName, formName, undefined, instanceId, undefined, tabId);
  }

  /**
   * Navigates to dynamic view data-entry form.
   *
   * Returns a promise that:
   * - resolves to 'true' when navigation succeeds,
   * - resolves to 'false' when navigation fails,
   * - is rejected when an error happens.
   *
   * @param {string} categorySystemName
   * @param {string} formName
   * @param {number} instanceId
   * @param {number} formId
   * @param {object} queryParams
   * @param {string} tabId
   * @returns {Promise<boolean>}
   */
  public navigateToViewDeForm(categorySystemName: string, formName: string, instanceId: number,
                              formId?: number, queryParams?: object, tabId?: string): Promise<boolean> {
    return this.navigateToDynamicDeForm(categorySystemName, formName, undefined, instanceId, undefined, tabId);
  }

  /**
   * Creates navigation url for create new dynamic de forms.
   * @param {string} categorySystemName
   * @param {string} formName
   * @returns {string}
   */
  public getUrlForCreateNewDeForm(categorySystemName: string, formName: string): Promise<string> {
    return this.getUrlForDynamicForm(categorySystemName, formName);
  }

  /**
   * Creates navigation url for edit dynamic de forms.
   * @param {string} categorySystemName
   * @param {string} formName
   * @param {number} instanceId
   * @returns {string}
   */
  public getUrlForEditDeForm(categorySystemName: string, formName: string, instanceId: number): Promise<string> {
    return this.getUrlForDynamicForm(categorySystemName, formName, instanceId);
  }

  /**
   * Creates navigation url for view dynamic de forms.
   * @param {string} categorySystemName
   * @param {string} formName
   * @param {number} instanceId
   * @returns {string}
   */
  public getUrlForViewDeForm(categorySystemName: string, formName: string, instanceId: number): Promise<string> {
    return this.getUrlForDynamicForm(categorySystemName, formName, instanceId);
  }

  public getUrlForCreateNewDeFormSync(categorySystemName: string, formName: string,
                                      formId?: number, queryParams?: object): string {
    throw new Error('Not implemented yet');
  }

  public getUrlForEditDeFormSync(categorySystemName: string, formName: string, instanceId: number,
                                 formId?: number, queryParams?: object): string {
    throw new Error('Not implemented yet');
  }

  public getUrlForViewDeFormSync(categorySystemName: string, formName: string, instanceId: number,
                                 formId?: number, queryParams?: object): string {
    throw new Error('Not implemented yet');
  }

  protected navigateToDynamicDeForm(categorySystemName: string, formName: string, formId?: number,
                                    instanceId?: number, queryParams?: object, tabId?: string): Promise<boolean> {
    return this.appRoutesReplaySubject.pipe(mergeMap((routes: RoutesModel) => {
      let url = this.findUrl(routes, categorySystemName, formName);
      if (instanceId !== undefined) {
        url = url.replace('{instanceId}', instanceId.toString());
      }
      if (tabId !== undefined) {
        url += '#' + tabId;
      }
      return this.router.navigateByUrl(url);
    })).toPromise();
  }

  protected getUrlForDynamicForm(categorySystemName: string, formName: string,
                                 instanceId?: number): Promise<string> {
    return this.appRoutesReplaySubject.pipe(map((routes: RoutesModel) => {
      const url = this.findUrl(routes, categorySystemName, formName);
      return instanceId === undefined ? url : url.replace('{instanceId}', instanceId.toString());
    })).toPromise();
  }

  private findUrl(routes: RoutesModel, categorySystemName: string, formName: string): string {
    const category: CategoryRouteModel | undefined = routes.categories.find((categoryModel: CategoryRouteModel) => {
      return categoryModel.category === categorySystemName;
    });
    if (category === undefined) {
      throw Error(`There is no ${categorySystemName} category in routes.json`);
    } else {
      const form: FormRouteModel | undefined = category.forms.find((formModel: FormRouteModel) => {
        return formModel.formId === formName;
      });
      if (form === undefined) {
        throw Error(`There is no ${formName} formName in ${categorySystemName} category in routes.json`);
      } else {
        return form.formUrl;
      }
    }
  }

}

interface RoutesModel {
  categories: CategoryRouteModel[];
}

interface CategoryRouteModel {
  category: string;
  forms: FormRouteModel[];
}

interface FormRouteModel {
  formId: string;
  formUrl: string;
}
