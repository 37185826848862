import {NgModule} from '@angular/core';
import {LanguagesStoreModule} from '@synisys/skynet-store-languages-impl';
import {MessageStoreModule} from '@synisys/skynet-store-messages-impl';
import {SisTranslateDisplayNamePipe} from './sis-translate-display-name.pipe';
import {SisTranslateMultilingualPipe} from './sis-translate-multilingual.pipe';
import {SisTranslatePipe} from './sis-translate.pipe';
import {TranslationService} from './translation.service';

@NgModule({
    declarations: [
        SisTranslatePipe,
        SisTranslateMultilingualPipe,
        SisTranslateDisplayNamePipe,
    ],
    exports: [
        SisTranslatePipe,
        SisTranslateMultilingualPipe,
        SisTranslateDisplayNamePipe,
    ],
    imports: [MessageStoreModule, LanguagesStoreModule],
    providers: [TranslationService],
})
export class SisTranslateModule {}
