import {LockData} from "../model/lock-data.model";
import {Observable} from "rxjs/Observable";

export abstract class LockingService {


    /**
     * Creates json to lock and returns to server side
     *
     * @param categoryId  - category system name for which action is being done
     * @param entityId
     * @param entityName
     */
    public abstract addLock(categoryId: string, entityId: number, entityName? : string): Observable<any>;

    /**
     * Removes category lock by given parameters in server side
     *
     * @param categoryId  - category system name for which action is being done
     * @param entityId
     */
    public abstract removeLock(categoryId: string, entityId: number): Observable<any>;

    /**
     * Gets lock data from server side by given params deserialize it and return
     *
     * @param categoryId  - category system name for which locking information must be asked
     * @param entityId
     */
    public abstract getLock(categoryId: string, entityId: number): Observable<LockData>;


    /**
     * Gets whole lock data deserialize it and returns
     *
     *@param page
     *@param size
     */
    public abstract getAllLocks(page: number, size: number): Observable<Array<LockData>>;


    /**
     * Whether category instance (recognised by method parameters) can
     * be edited for current user and returns via boolean observable.
     *
     * @param categoryId  - category system name for which locking information must be asked
     * @param entityId
     */
    public abstract canEdit(categoryId: string, entityId: number): Observable<boolean>;

    /**
     * Whether category instance (recognised by method parameters) can
     * be deleted for current user and returns via boolean observable.
     *
     * @param categoryId  - category system name for which locking information must be asked
     * @param entityId
     */
    public abstract canDelete(categoryId: string, entityId: number): Observable<boolean>;

    /**
     * Gets permanent lock data from server side by given params deserialize it and return
     *
     * @param categoryId  - category system name for which locking information must be asked
     * @param entityId
     */
    public abstract getPermanentLock(categoryId: string, entityId: number): Observable<LockData>;

}
