import {Pipe, PipeTransform} from '@angular/core';
import {MultilingualString} from '@synisys/idm-crosscutting-concepts-frontend';
import {Observable} from 'rxjs/Observable';
import {TranslationService} from './translation.service';

@Pipe({
    name: 'sisTranslateMultilingual',
})
export class SisTranslateMultilingualPipe implements PipeTransform {
    constructor(private readonly translationService: TranslationService) {}

    public transform(
        multilingual:
            | MultilingualString
            | {[key: string]: string}
            | string
            | undefined,
        defaultValue: string = ''
    ): Observable<string> {
        return this.translationService.translateMultilingual(
            multilingual,
            defaultValue
        );
    }
}
