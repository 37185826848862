import {NgModule} from '@angular/core';
import {LanguagesStoreModule} from '@synisys/skynet-store-languages-impl';
import {LocaleStoreModule} from '@synisys/skynet-store-locales-impl';
import {LocalDatePipe, SisCatchPipe} from './pipes';
import {FormattingService} from './services';

@NgModule({
    declarations: [SisCatchPipe, LocalDatePipe],
    exports: [SisCatchPipe, LocalDatePipe],
    imports: [LocaleStoreModule, LanguagesStoreModule],
    providers: [FormattingService],
})
export class SisUtilitiesModule {}
