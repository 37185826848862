import {Pipe, PipeTransform} from '@angular/core';
import {MultilingualString} from '@synisys/idm-crosscutting-concepts-frontend';
import {notNil, NullOrUndefined} from '@synisys/skynet-store-utilities';
import {Observable} from 'rxjs/Observable';
import {of} from 'rxjs/observable/of';
import {TranslationService} from './translation.service';

@Pipe({
    name: 'sisTranslateDisplayName',
})
export class SisTranslateDisplayNamePipe implements PipeTransform {
    constructor(private readonly translationService: TranslationService) {}

    public transform(
        item: {
            getDisplayNameMsgId?: () => string | NullOrUndefined;
            getDisplayName?: () => string | NullOrUndefined;
            getDisplayNameMultilingual?: () =>
                | MultilingualString
                | NullOrUndefined;
        },
        defaultValue: string = ''
    ): Observable<string> {
        if (
            item.getDisplayNameMultilingual &&
            notNil(item.getDisplayNameMultilingual())
        ) {
            return this.translationService.translateMultilingual(
                item.getDisplayNameMultilingual()!,
                defaultValue
            );
        } else if (
            item.getDisplayNameMsgId &&
            notNil(item.getDisplayNameMsgId())
        ) {
            return this.translationService.translate(
                item.getDisplayNameMsgId()!,
                defaultValue
            );
        } else if (item.getDisplayName && notNil(item.getDisplayName())) {
            return this.translationService.translate(
                item.getDisplayName()!,
                defaultValue
            );
        } else {
            return of(defaultValue);
        }
    }
}
