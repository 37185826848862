import {Observable} from "rxjs/Observable";
import {map, switchMap} from 'rxjs/operators';
import {LockingService} from "../api/lock.service";
import {LockData} from "../model/lock-data.model";
import {Injectable} from "@angular/core";
import {StringTemplate} from "@synisys/idm-common-util-frontend";
import {ApplicationPropertiesService} from "@synisys/idm-application-properties-service-client-js";
import {AuthenticationService, HttpClientWrapper, UserData} from "@synisys/idm-authentication-client-js";
import {Logger} from "../helper/logger";
import "rxjs/add/observable/from";
import "rxjs/add/observable/combineLatest";
import "rxjs/add/operator/mergeMap";
import "rxjs/add/operator/do";
import "rxjs/add/operator/catch";
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';


@Injectable()
export class LockingHttpService implements LockingService {

    public URL_LOCK_ADD: StringTemplate = StringTemplate.createTemplate`${"serviceUrl"}/locks/${"userId"}/${"categoryId"}/${"entityId"}`;
    public URL_LOCK_REMOVE: StringTemplate = StringTemplate.createTemplate`${"serviceUrl"}/locks/${"userId"}/${"categoryId"}/${"entityId"}`;
    public URL_LOCK_LOAD_ONE: StringTemplate = StringTemplate.createTemplate`${"serviceUrl"}/locks/${"categoryId"}/${"entityId"}`;

    public URL_LOCK_LOAD_ALL: StringTemplate = StringTemplate.createTemplate`${"serviceUrl"}/locks`;
    public URL_LOCK_CAN_EDIT: StringTemplate = StringTemplate.createTemplate`${"serviceUrl"}/generallocks/edit/${"categoryId"}/${"entityId"}/${"userId"}`;
    public URL_LOCK_CAN_DELETE: StringTemplate = StringTemplate.createTemplate`${"serviceUrl"}/generallocks/delete/${"categoryId"}/${"entityId"}/${"userId"}`;

    public URL_PERMANENT_LOCK_LOAD_ONE: StringTemplate = StringTemplate.createTemplate`${'serviceUrl'}/permanentlocks/${'categoryId'}/${'entityId'}`;


    private lockingServiceUrlKey: string = "lock-service-url";
    private lockingServiceUrlKey$: Observable<string>;
    private authenticatedUserData$: Observable<UserData>;

    public constructor(private http: HttpClient,
                       private applicationPropertiesService: ApplicationPropertiesService,
                       private authenticationService: AuthenticationService) {
        this.lockingServiceUrlKey$ = Observable.from(this.applicationPropertiesService.getProperty(this.lockingServiceUrlKey));
        this.authenticatedUserData$ = this.authenticationService.getUserData();
    }


    public addLock(categoryId: string, entityId: number, entityName?: string): Observable<any> {
        let url$ = Observable.combineLatest(this.authenticatedUserData$, this.lockingServiceUrlKey$)
            .map(data => {
                let userData: UserData = data[0];
                let serviceUrl: string = data[1];
                return this.URL_LOCK_ADD.replaceTemplate({
                    "serviceUrl": serviceUrl,
                    "categoryId": categoryId,
                    "entityId": entityId.toString(),
                    "userId": userData.userId.toString()
                });
            });
        return url$.mergeMap(url => {
            let httpParams = new HttpParams().set("entityName", entityName ? entityName : categoryId + entityId);
            return this.http.post(url, null, {params: httpParams})
        });

    }

    public removeLock(categoryId: string, entityId: number): Observable<any> {
        let url$ = Observable.combineLatest(this.authenticatedUserData$, this.lockingServiceUrlKey$)
            .map(data => {
                let userData: UserData = data[0];
                let serviceUrl: string = data[1];

                return this.URL_LOCK_REMOVE.replaceTemplate({
                    "serviceUrl": serviceUrl,
                    "categoryId": categoryId,
                    "entityId": entityId.toString(),
                    "userId": userData.userId.toString()
                });
            });

        return url$.mergeMap(url => {
            return this.http.delete(url)
        });
    }


    public removeLockByEntityId(categoryId: string, entityId: number): Observable<any> {
        let url$ = Observable.combineLatest(this.authenticatedUserData$, this.lockingServiceUrlKey$)
            .map(data => {
                let userData: UserData = data[0];
                let serviceUrl: string = data[1];

                return this.URL_LOCK_REMOVE.replaceTemplate({
                    "serviceUrl": serviceUrl,
                    "categoryId": categoryId,
                    "entityId": entityId.toString(),
                    "userId": userData.userId.toString()
                });
            });

        return url$.mergeMap(url => {
            return this.http.delete(url)
        });
    }


    public getLock(categoryId: string, entityId: number): Observable<LockData> {
        let url$ = this.lockingServiceUrlKey$
            .map(lockingServiceUrl => {
                return this.URL_LOCK_LOAD_ONE.replaceTemplate({
                    "serviceUrl": lockingServiceUrl,
                    "categoryId": categoryId,
                    "entityId": entityId.toString()
                });
            });

        return url$.mergeMap(url => {
            Logger.log("LockingService:loadlock:" + url);
            return this.http.get(url);
        }).map(lockJson => deserializeLockData(categoryId, entityId, lockJson));
    }

    public getAllLocks(page: number, size: number): Observable<Array<LockData>> {
        let url$ = this.lockingServiceUrlKey$
            .map(lockingServiceUrl => {
                return this.URL_LOCK_LOAD_ALL.replaceTemplate({
                    "serviceUrl": lockingServiceUrl
                });
            });

        return url$.mergeMap(url => {
            let httpParams = new HttpParams().set("page", page.toString()).set("size", size.toString());
            return this.http.get(url, {params: httpParams});
        }).map(allLockDataJson => deserializeAllLockData(allLockDataJson));
    }

    public canEdit(categoryId: string, entityId: number): Observable<boolean> {
        let url$ = Observable.combineLatest(this.authenticatedUserData$, this.lockingServiceUrlKey$)
            .map(data => {
                let userData: UserData = data[0];
                let serviceUrl: string = data[1];
                return this.URL_LOCK_CAN_EDIT.replaceTemplate({
                    "serviceUrl": serviceUrl,
                    "categoryId": categoryId,
                    "entityId": entityId.toString(),
                    "userId": userData.userId.toString(),
                });
            });

        return url$.mergeMap(url => {
            return this.http.get(url);
        }).map(data => (data.toString() === 'true'));
    }

    public canDelete(categoryId: string, entityId: number): Observable<boolean> {
        let url$ = Observable.combineLatest(this.authenticatedUserData$, this.lockingServiceUrlKey$)
            .map(data => {
                let userData: UserData = data[0];
                let serviceUrl: string = data[1];
                return this.URL_LOCK_CAN_DELETE.replaceTemplate({
                    "serviceUrl": serviceUrl,
                    "categoryId": categoryId,
                    "entityId": entityId.toString(),
                    "userId": userData.userId.toString(),
                });
            });
        return url$.mergeMap(url => {
            return this.http.get(url);
        }).map(data => (data.toString() === 'true'));
    }

    public getPermanentLock(categoryId: string,
                            entityId: number): Observable<LockData> {
        return this.lockingServiceUrlKey$
            .pipe(
                map(lockingServiceUrl =>
                        this.URL_PERMANENT_LOCK_LOAD_ONE
                            .replaceTemplate({
                                                 'serviceUrl': lockingServiceUrl,
                                                 'categoryId': categoryId,
                                                 'entityId': entityId.toString()
                                             })),
                switchMap(url => this.http.get(url)),
                map(lockJson => deserializeLockData(categoryId, entityId,
                                                    lockJson))
            );

    }


}


export function deserializeLockData(categoryId: string, entityId: number, jsonItem: any): LockData {
    if (jsonItem == null || jsonItem && !jsonItem.userId) {
        return new LockData(null, null, null, null, null, null, null);
    }
    let lockData = new LockData(
        jsonItem.categoryId,
        jsonItem.entityId,
        jsonItem.entityName,
        jsonItem.userId,
        jsonItem.userFullName,
        jsonItem.timestamp,
        jsonItem.sessionKey);
    Logger.log(`LockingService:${categoryId}:${entityId} is ${lockData.getIsLocked() ? 'locked' : 'not locked'}`);
    return lockData;
}

export function deserializeAllLockData(allLockJson: any): Array<LockData> {
    let allLockData: Array<LockData> = [];
    let allLockDataSerialized: any = allLockJson.json ? allLockJson.json() : allLockJson; //done for loading json from file as well
    allLockDataSerialized.forEach((item: any) => {
        allLockData.push(deserializeLockData(item.categoryId, item.entityId, item))
    });
    return allLockData;
}
