import {Pipe, PipeTransform} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {TranslationService} from './translation.service';

@Pipe({
    name: 'sisTranslate',
})
export class SisTranslatePipe implements PipeTransform {
    constructor(private readonly translationService: TranslationService) {}

    public transform(
        name: string,
        defaultValue: string = '',
        replaceTo: string[] = []
    ): Observable<string> {
        return this.translationService.translate(name, defaultValue, replaceTo);
    }
}
