import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {LanguagesState} from '@synisys/skynet-store-languages-api';
import {
    LocaleInfo,
    LocaleState,
    localeStoreManager,
} from '@synisys/skynet-store-locales-api';
import {notNil, NullOrUndefined} from '@synisys/skynet-store-utilities';
import {isNil} from 'lodash';
import * as moment from 'moment';
import {Observable} from 'rxjs/Observable';
import {filter, map, switchMap} from 'rxjs/operators';

require('moment-jdateformatparser');

interface MomentJDFP extends moment.Moment {
    // interface for moment-jdateformatparser
    formatWithJDF(pattern: string): string;
}

@Injectable()
export class FormattingService {
    private static resolveTimezone(
        timezoneOffset: number | NullOrUndefined
    ): number {
        if (isNil(timezoneOffset)) {
            return -new Date().getTimezoneOffset();
        } else {
            return timezoneOffset / 60;
        }
    }

    private static getLocale(language: string, country: string): string {
        return language + '-' + country;
    }

    constructor(private store: Store<LocaleState & LanguagesState>) {}

    public formatDate(utcTime: number): Observable<string> {
        return this.getCurrentLocale().pipe(
            map(localeInfo => {
                const date = moment(utcTime).utcOffset(
                    FormattingService.resolveTimezone(localeInfo.timeZoneOffset)
                ) as MomentJDFP;
                date.locale(
                    FormattingService.getLocale(
                        localeInfo.locale,
                        localeInfo.country
                    )
                );
                return date.formatWithJDF(localeInfo.datePattern);
            })
        );
    }

    public formatTime(utcTime: number): Observable<string> {
        return this.getCurrentLocale().pipe(
            map(localeInfo => {
                const date = moment(utcTime).utcOffset(
                    FormattingService.resolveTimezone(localeInfo.timeZoneOffset)
                ) as MomentJDFP;
                date.locale(
                    FormattingService.getLocale(
                        localeInfo.locale,
                        localeInfo.country
                    )
                );
                return date.formatWithJDF(localeInfo.timePattern);
            })
        );
    }

    public formatDateTime(utcTime: number): Observable<string> {
        return this.getCurrentLocale().pipe(
            map(localeInfo => {
                const date = moment(utcTime).utcOffset(
                    FormattingService.resolveTimezone(localeInfo.timeZoneOffset)
                ) as MomentJDFP;
                date.locale(
                    FormattingService.getLocale(
                        localeInfo.locale,
                        localeInfo.country
                    )
                );
                return date.formatWithJDF(
                    localeInfo.datePattern + ' ' + localeInfo.timePattern
                );
            })
        );
    }

    public formatNumber(
        number: number,
        options?: {
            groupSeparator?: string;
            decimalSeparator?: string;
            fractionDigits?: number;
        }
    ): Observable<string> {
        return this.getCurrentLocale().pipe(
            map(localeInfo => {
                const numberFormat = {
                    ...localeInfo.numberFormat,
                    ...options,
                };
                const str = Boolean(numberFormat.fractionDigits)
                    ? number.toFixed(numberFormat.fractionDigits)
                    : String(number);

                const groupIndent = /\B(?=(\d{3})+(?!\d))/g;
                if (str.includes('.')) {
                    const parts = str.split('.');
                    return (
                        parts[0].replace(
                            groupIndent,
                            numberFormat.groupSeparator
                        ) +
                        numberFormat.decimalSeparator +
                        parts[1]
                    );
                } else {
                    return str.replace(
                        groupIndent,
                        numberFormat.groupSeparator
                    );
                }
            })
        );
    }

    public parseNumber(num: string): Observable<number | undefined> {
        return this.getCurrentLocale().pipe(
            map((localeInfo: LocaleInfo) => {
                return parseFloat(
                    num
                        .replace(
                            new RegExp(
                                '[^\\{separator}0-9]+'.replace(
                                    '{separator}',
                                    localeInfo.numberFormat.decimalSeparator
                                ),
                                'g'
                            ),
                            ''
                        )
                        .replace(localeInfo.numberFormat.decimalSeparator, '.')
                );
            }),
            map(value => (isNaN(value) ? undefined : value))
        );
    }

    public getCurrentLocale(): Observable<LocaleInfo> {
        return this.store
            .select(state => state.languages.currentLanguageId)
            .pipe(
                filter(notNil),
                switchMap(currentLanguageId =>
                    localeStoreManager.selectOne(this.store, currentLanguageId)
                ),
                filter(notNil)
            );
    }
}
