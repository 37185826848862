/**
 * Created by anania.mikaelyan on 12/15/16.
 */

export class LockData {
    constructor(private _categoryId: string,
                private _entityId: number,
                private _entityName: string,
                private _userId: number,
                private _userFullName: string,
                private _timestamp: number,
                private _sessionKey: string) { }


    public getIsLocked() : boolean{
        return this._userId !== null;
    }

    get categoryId(): string {
        return this._categoryId;
    }

    get entityId(): number {
        return this._entityId;
    }

    get entityName(): string {
        return this._entityName;
    }

    get userId(): number {
        return this._userId;
    }

    get userFullName(): string {
        return this._userFullName;
    }

    get timestamp(): number {
        return this._timestamp;
    }

    get sessionKey(): string {
        return this._sessionKey;
    }


}
