import {Logger} from '@synisys/skynet-store-utilities';
import {MultilingualString} from '@synisys/idm-crosscutting-concepts-frontend';

export function createLogger(namespace: string): Logger {
    return new Logger('idm-frontend-shared', namespace);
}

export function buildMultilingualStringFromObject(
    obj: object
): MultilingualString {
    const builder = MultilingualString.newBuilder();

    Object.keys(obj).map((key: string) => {
        if (obj.hasOwnProperty(key)) {
            builder.withValueForLanguage(+key, obj[key]);
        }
    });

    return builder.build();
}

export function createObjectFromMultilingualString(
    text: MultilingualString
): object | undefined | null {
    if (text) {
        const map = text.toMap();
        const obj = {};
        Array.from(map).map((entry: [number, string]) => {
            obj[entry[0]] = entry[1];
        });
        return obj;
    }
    return text;
}
