/**
 * Created by Anushavan.Hovsepyan on 12/2/2016.
 */

export class Logger {

    public static isDevelopmentMode: boolean = false;

    constructor() { }

    public static log(message: any): void {
        Logger.isDevelopmentMode && console.log(message);
    }
}