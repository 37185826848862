import {ChangeDetectorRef, NgZone} from '@angular/core';
import {ScrollableElement} from './scrollable-element';

export class PageHeader implements ScrollableElement {
    public static EMPTY = new PageHeader(0, undefined);
    public transition = 0;

    constructor(
        public readonly height: number,
        public readonly ngZone: NgZone | undefined,
        public readonly cd?: ChangeDetectorRef
    ) {}

    public scroll(scrollBy: number): void {
        let possibleTransition = this.transition - scrollBy;
        possibleTransition = this.correctTransition(possibleTransition);
        if (this.ngZone) {
            this.transition = this.ngZone.run(() => {
                const oldValue = this.transition;
                const changed = oldValue !== possibleTransition;
                this.transition = possibleTransition;
                if (changed && this.cd) {
                    this.cd.detectChanges();
                }
                return possibleTransition;
            });
        } else {
            this.transition = possibleTransition;
        }
    }

    private correctTransition(possibleTransition: number): number {
        const transitionMin = this.height * -1;
        if (possibleTransition < transitionMin) {
            possibleTransition = transitionMin;
        } else if (possibleTransition > 0) {
            possibleTransition = 0;
        }
        return possibleTransition;
    }
}
