import {Pipe, PipeTransform} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {tap} from 'rxjs/operators';
import {noop} from 'rxjs/util/noop';
import {createLogger} from '../utilities';

const logger = createLogger('sisCatch');

@Pipe({
    name: 'sisCatch',
})
export class SisCatchPipe implements PipeTransform {
    public transform<T>(value$: Observable<T>): Observable<T> {
        return value$.pipe(
            tap(noop, err => logger.error('caught following error %s', err))
        );
    }
}
