import {Injectable} from "@angular/core";
import {ApplicationPropertiesService} from "@synisys/idm-application-properties-service-client-js";
import {StringTemplate} from "@synisys/idm-common-util-frontend";
import {MultilingualString} from '@synisys/idm-crosscutting-concepts-frontend';
import {languageStoreManager} from '@synisys/skynet-store-languages-api';
import {
    Message,
    MessageKey,
    MessageKeyFactory, messageStoreManager
} from '@synisys/skynet-store-messages-api';
import {isEmptyMultilingual, notNil} from '@synisys/skynet-store-utilities';
import {of} from 'rxjs/observable/of';
import {zip} from 'rxjs/observable/zip';
import {MetaField, MetaFieldType} from "../model/meta-field";
import {CategoryType, KbDeserializationHelper} from "./kb-deserialization-helper";
import {KbService} from "../api/kb.service";
import {MetaCategory} from "../model/meta-category";
import {MetaGroup} from "../model/meta-group";
import {MetaFieldId} from "../model/meta-field-id";
import {MetaCategoryId} from "../model/meta-category-id";
import {Observable} from "rxjs/Observable";
import {filter, map, tap, switchMap, mergeMap} from 'rxjs/operators';
import {HttpResponse} from "@angular/common/http";
import {HierarchicalMetaCategoryId} from "../model/hierarchical-meta-category-id";
import {HttpClientWrapper} from "@synisys/idm-authentication-client-js"
import {MetaGroupId} from "../model/meta-group-id";
import {shareReplay} from "rxjs/operators";
import {Store} from '@ngrx/store';
import {
    categoryStoreManager,
    fieldStoreManager,
    groupStoreManager,
    KbState,
    MetaField as StoreMetaField,
    MetaFieldId as StoreMetaFieldId,
    MetaCategory as StoreMetaCategory,
    ENTITY_MULTILINGUAL_NAME_ATTRIBUTE,
    EntityWithName
} from '@synisys/skynet-store-kb-api';
import {MetaFieldTypeHelper} from "./meta-field-type-helper";
import {isNil} from "lodash";

import * as _ from "immutable";


@Injectable()
export class KbHttpService implements KbService {

    private kbApiUrlKey: string = 'kb-service-url';
    private cacheKeyAllCategories: string = 'allCategories';
    private cacheKeyClassifierCategories: string = 'classifierCategories';
    private cacheKeyMainEntityCategories: string = 'mainEntityCategories';
    private cacheKeyCategoryManagerCategories: string = 'categoryCategories';
    private cacheKeyHierarchicalCategories: string = 'hierarchicalcategories';


    public urlLoadCategory = StringTemplate.createTemplate`${"serviceUri"}/categories/${"categorySystemName"}`;
    public urlLoadCategoryWithCustomPrefix = StringTemplate.createTemplate`${"serviceUri"}/${"customPrefix"}/categories/${"categorySystemName"}`;
    public urlLoadCategories = StringTemplate.createTemplate`${"serviceUri"}/categories`;
    public urlLoadCategoriesWithCustomPrefix = StringTemplate.createTemplate`${"serviceUri"}/${"customPrefix"}/categories`;

    public urlLoadHierarchicalCategories = StringTemplate.createTemplate`${"serviceUri"}/hierarchicalcategories`;
    public urlLoadHierarchicalCategoriesWithCustomPrefix = StringTemplate.createTemplate`${"serviceUri"}/${"customPrefix"}/hierarchicalcategories`;

    public urlLoadMainEntityCategoryFields = StringTemplate.createTemplate`${"serviceUri"}/mainentityfields/${"categorySystemName"}/${"fieldSystemName"}`;
    public urlLoadMainEntityCategoryFieldsWithCustomPrefix = StringTemplate.createTemplate`${"serviceUri"}/${"customPrefix"}/mainentityfields/${"categorySystemName"}/${"fieldSystemName"}`;

    public static WORKFLOW_ACTION_ID_SYSTEM_NAME = "lastActionId";

    protected kbDeserializationHelper: KbDeserializationHelper;
    protected kbServiceUrl$: Observable<string>;
    protected cachedData: Map<string, Observable<any>> = new Map<string, Observable<any>>();

    constructor(private http: HttpClientWrapper,
                private readonly store: Store<KbState>,
                private applicationPropertiesService: ApplicationPropertiesService) {

        this.kbDeserializationHelper = new KbDeserializationHelper();
        this.kbServiceUrl$ = Observable.from(this.applicationPropertiesService.getProperty(this.kbApiUrlKey));
    }

    public getCategories(): Observable<Set<MetaCategory>> {
        return this.getAllMetaCategories();
    }

    public getAllMetaCategories(customPrefix ?: string): Observable<Set<MetaCategory>> {

        return categoryStoreManager(customPrefix)
            .selectAll(this.store)
            .pipe(map(values => new Set(values.map(item => fromStoreMetaCategory(item)).valueSeq().toArray())));

    }

    public getClassifierMetaCategories(customPrefix ?: string): Observable<Set<MetaCategory>> {
        return this.getMetaCategories(this.cacheKeyClassifierCategories, CategoryType.CLASSIFIER, customPrefix);
    }

    public getMainEntityMetaCategories(customPrefix ?: string): Observable<Set<MetaCategory>> {
        return this.getMetaCategories(this.cacheKeyMainEntityCategories, CategoryType.MAIN_ENTITY, customPrefix)
    }

    public getCategoryManagerMetaCategories(customPrefix ?: string): Observable<Set<MetaCategory>> {
        return this.getMetaCategories(this.cacheKeyCategoryManagerCategories, CategoryType.CATEGORY_MANAGER,
                                      customPrefix)
    }

    public getMetaCategoryByMetaCategoryId(metaCategoryId: MetaCategoryId,
                                           customPrefix ?: string): Observable<MetaCategory> {
        const categorySystemName = metaCategoryId.getSystemName();

        return categoryStoreManager(customPrefix)
            .selectAll(this.store)
            .pipe(map(values => values.map(item => fromStoreMetaCategory(item))
                .valueSeq()
                .find(item => item.getSystemName()
                                  .toLowerCase() === categorySystemName.toLowerCase())));
    }

    public getMetaFields(categorySystemName: string, customPrefix ?: string): Observable<Array<MetaField>> {
        return fieldStoreManager(customPrefix).selectAll(this.store, categorySystemName)
                                              .pipe(map(list =>
                                                            list.map(item => fromStoreMetaField(item)).valueSeq()
                                                                .toArray()))
    }


    public getNonSystemMetaFields(categorySystemName: string, customPrefix ?: string): Observable<Array<MetaField>> {
        return this.getMetaFields(categorySystemName, customPrefix).map(metaFields => {
            return metaFields.filter(item => {
                return !item.getIsSystemField()
            })
        })
    }

    public getMainEntityMetaFields(categorySystemName: string,
                                   mainEntityMetaFieldSystemName: string,
                                   customPrefix ?: string): Observable<Array<MetaField>> {
        let cacheKey: string = `${categorySystemName}-${mainEntityMetaFieldSystemName}MainEntityFields${customPrefix}`;
        if (!this.cachedData.get(cacheKey)) {
            this.cachedData.set(cacheKey,
                                this.kbServiceUrl$
                                    .mergeMap(kbServiceUrl => {
                                        let urlTemplate = !customPrefix ?
                                                          this.urlLoadMainEntityCategoryFields :
                                                          this.urlLoadMainEntityCategoryFieldsWithCustomPrefix;
                                        let url: string = urlTemplate.replaceTemplate(
                                            {
                                                'serviceUri'        : kbServiceUrl,
                                                'categorySystemName': categorySystemName,
                                                'fieldSystemName'   : mainEntityMetaFieldSystemName,
                                                'customPrefix'      : customPrefix
                                            });
                                        return this.http.get(url)
                                            .pipe(
                                                mergeMap(
                                                    (response: object[]) => {
                                                        if (response.length === 0) {
                                                            return of([]);
                                                        }
                                                        return zip(
                                                            ...response.map(
                                                                item => this.correctNameOfEntity(
                                                                    item)))
                                                    }),
                                                map((response: object[]) => {
                                                    return this.kbDeserializationHelper.extractFields(
                                                        categorySystemName,
                                                        response)
                                                }));
                                    }).pipe(
                                    shareReplay(1)
                                ));
        }
        return this.cachedData.get(cacheKey);

    }

    public getMetaFieldByMetaFieldId(metaFieldId: MetaFieldId, customPrefix  ?: string): Observable<MetaField> {

        return fieldStoreManager(customPrefix).selectAll(this.store, metaFieldId.getMetaCategoryId().getSystemName())
                                              .pipe(map(list => fromStoreMetaField(list.findLast(
                                                  item => item.metaFieldId.systemName ===
                                                      metaFieldId.getSystemName()))));
    }


    public getMetaGroups(categorySystemName: string, customPrefix ?: string): Observable<MetaGroup[]> {
        return this.getNonSystemMetaGroups(categorySystemName, customPrefix);
    }

    public getAllMetaGroups(categorySystemName: string, customPrefix?: string): Observable<MetaGroup[]> {
        return groupStoreManager(customPrefix).selectAll(this.store, categorySystemName).pipe(
            switchMap(
                groups => this.kbDeserializationHelper.extractGroups(this.getMetaFields(categorySystemName), groups,
                                                                     false)))
    }

    public getNonSystemMetaGroups(categorySystemName: string, customPrefix?: string): Observable<MetaGroup[]> {
        return groupStoreManager(customPrefix).selectAll(this.store, categorySystemName).pipe(
            switchMap(
                groups => this.kbDeserializationHelper.extractGroups(this.getMetaFields(categorySystemName), groups,
                                                                     true)))
    }

    public getHierarchicalMetaCategoryIds(customPrefix ?: string): Observable<Array<HierarchicalMetaCategoryId>> {
        let cacheKey: string = `${this.cacheKeyHierarchicalCategories}${customPrefix}`;
        if (!this.cachedData.get(cacheKey)) {
            this.cachedData.set(cacheKey, this.kbServiceUrl$.mergeMap(kbServiceUrl => {
                let urlTemplate: StringTemplate = !customPrefix ? this.urlLoadHierarchicalCategories :
                                                  this.urlLoadHierarchicalCategoriesWithCustomPrefix;

                let url: string = urlTemplate.replaceTemplate({
                                                                  "serviceUri"  : kbServiceUrl,
                                                                  "customPrefix": customPrefix
                                                              });

                return this.http.get(url).map((responseData: HttpResponse<any>) => {
                    return this.extractHierarchicalMetaCategories(responseData);
                });
            })
                                              .pipe(shareReplay(1))
                                              .catch(this.handleError));
        }
        return this.cachedData.get(cacheKey);

    }

    private getMetaCategories(categoryCacheKey: string, categoryType: CategoryType,
                              customPrefix ?: string): Observable<Set<MetaCategory>> {

        return categoryStoreManager(customPrefix)
            .selectAll(this.store).pipe(map(values =>
                                                this.extractMetaCategories(
                                                    values.map(item => fromStoreMetaCategory(item)).valueSeq().toList(),
                                                    categoryType)))

    }

    public extractMetaFieldId(metaFieldIdJson: any): MetaFieldId {
        return this.kbDeserializationHelper.extractMetaFieldId(metaFieldIdJson);
    }

    public getDisplayNameMetaFields(categorySystemName: string): Observable<Array<MetaField>> {
        return Observable.from([]);
    }

    public getNameMetaFieldIds(categorySystemName: string, customPrefix ?: string): Observable<Array<MetaFieldId>> {
        return this.getMetaCategoryByMetaCategoryId(new MetaCategoryId(categorySystemName), customPrefix)
                   .map((metaCategory: MetaCategory) => {
                       return metaCategory.getNameMetaFieldIds()
                   });
    }

    public getKeyMetaFieldIds(categorySystemName: string, customPrefix ?: string): Observable<Array<MetaFieldId>> {
        return this.getMetaCategoryByMetaCategoryId(new MetaCategoryId(categorySystemName), customPrefix)
                   .map((metaCategory: MetaCategory) => {
                       return metaCategory.getKeyMetaFieldIds()
                   });
    }


    public getClassifierViewMetaFields(): Observable<Array<MetaField>> {
        return Observable.from([
                                   [
                                       new MetaField(
                                           new MetaFieldId(new MetaCategoryId(""), "id", new MetaGroupId("general")),
                                           "kb_id", MetaFieldType.INTEGER_IDENTITY, "INTEGER_IDENTITY", false),
                                       new MetaField(
                                           new MetaFieldId(new MetaCategoryId(""), "name", new MetaGroupId("general")),
                                           "kb_name", MetaFieldType.STRING, "STRING", false)
                                   ]
                               ]);
    }


    public getAncestorsMetaCategoryIds(metaCategoryId: MetaCategoryId): Observable<Array<MetaCategoryId>> {
        return this.getMetaFields(metaCategoryId.getSystemName())
                   .flatMap((metaFields: Array<MetaField>) => {

                       let parentCategory: MetaField = metaFields.find((metaField: MetaField) => {
                           return metaField.getType() === MetaFieldType.PARENT;
                       });

                       if (parentCategory) {

                           let parentCategorySystemName: string = parentCategory.getCompoundCategorySystemName();
                           let parentMetaCategoryId: MetaCategoryId = new MetaCategoryId(parentCategorySystemName);
                           return this.getAncestorsMetaCategoryIds(parentMetaCategoryId)
                                      .map((ancestors: Array<MetaCategoryId>) => {
                                          ancestors.unshift(parentMetaCategoryId);
                                          return ancestors;
                                      })
                       }
                       return Observable.of([]);
                   });
    }

    public getIdentityMetaField(categorySystemName: string, customPrefix ?: string): Observable<MetaField> {
        return this.getMetaFields(categorySystemName, customPrefix)
                   .map((metaFields: Array<MetaField>) => {
                       const identity = metaFields.find((metaField: MetaField) => {
                           return metaField.getType() === MetaFieldType.INTEGER_IDENTITY;
                       });
                       if (!identity) {
                           throw new Error(categorySystemName + " category doesn't have identity metaField")
                       }
                       return identity
                   });
    }

    public getInstanceMetaField(categorySystemName: string, customPrefix ?: string): Observable<MetaField> {
        return this.getMetaFields(categorySystemName, customPrefix)
                   .map((metaFields: Array<MetaField>) => {
                       const instance = metaFields.find((metaField: MetaField) => {
                           return metaField.getType() === MetaFieldType.INTEGER_INSTANCE;
                       });
                       return instance ? instance : null
                   });
    }

    public getWorkflowStateMetaField(categorySystemName: string, customPrefix ?: string): Observable<MetaField> {
        return this.getMetaFields(categorySystemName, customPrefix)
                   .map((metaFields: Array<MetaField>) => {
                       const wfState = metaFields.find((metaField: MetaField) => {
                           return metaField.getType() === MetaFieldType.WORKFLOW_STATE;
                       });
                       return wfState ? wfState : null
                   });
    }

    public getWorkflowActionMetaFieldId(metaCategoryId: MetaCategoryId,
                                        customPrefix?: string): MetaFieldId {
        return new MetaFieldId(metaCategoryId,
                               KbHttpService.WORKFLOW_ACTION_ID_SYSTEM_NAME,
                               null);
    }


    public resetCache(): void {
        this.cachedData = new Map<string, Observable<any>>();
    }


    protected extractMetaCategories(responseData: any, categoryType: CategoryType): Set<MetaCategory> {
        return this.kbDeserializationHelper.extractCategories(responseData, categoryType);
    }

    protected extractHierarchicalMetaCategories(responseData: any): Array<HierarchicalMetaCategoryId> {
        return this.kbDeserializationHelper.extractHierarchicalCategories(responseData);
    }

    private handleError(error: any) {
        let errMsg = (error.message) ? error.message :
                     error.status ? `${error.status} - ${error.statusText}` : 'Server error';
        console.error(errMsg);
        return Observable.throw(errMsg);
    }

    private getUrlForCategory(serviceUrl: string, customPrefix: string, categorySystemName: string) {
        if (categorySystemName) {
            let urlTemplate: StringTemplate = !customPrefix ? this.urlLoadCategory
                                                            : this.urlLoadCategoryWithCustomPrefix;
            return urlTemplate.replaceTemplate({
                                                   "serviceUri"        : serviceUrl,
                                                   "customPrefix"      : customPrefix,
                                                   "categorySystemName": categorySystemName
                                               });
        }
        let urlTemplate: StringTemplate = !customPrefix ? this.urlLoadCategories
                                                        : this.urlLoadCategoriesWithCustomPrefix;
        return urlTemplate.replaceTemplate({
                                               "serviceUri"  : serviceUrl,
                                               "customPrefix": customPrefix,
                                           });
    }

    private correctNameOfEntity<T extends EntityWithName>(
        entityWithName: T
    ): Observable<T> {
        if (
            isEmptyMultilingual(entityWithName.displayNameMultilingual) &&
            !isNil(entityWithName.displayName)
        ) {
            return this.convertMessageToMultilingual(
                entityWithName.displayName
            ).pipe(
                map(name => {
                    entityWithName.displayNameMultilingual = name;
                    return entityWithName;
                })
            );
        } else if (
            isNil(entityWithName.displayNameMultilingual) &&
            isNil(entityWithName.displayName)
        ) {
            entityWithName.displayNameMultilingual = MultilingualString.newBuilder().build();
            return of(entityWithName);
        } else {
            return of(entityWithName);
        }
    }


    private convertMessageToMultilingual(
        name: string
    ): Observable<MultilingualString> {
        return languageStoreManager.selectAll(this.store).pipe(
            map(languages =>
                    languages
                        .toSeq()
                        .map(language => language.getId())
                        .map(languageId => MessageKeyFactory({name, languageId}))
            ),
            switchMap(
                (keys): Observable<Message[]> => {
                    return zip(
                        ...keys
                            .map(key =>
                                     messageStoreManager
                                         .selectOne(this.store, key)
                                         .pipe(filter(notNil))
                            )
                            .toList()
                            .toArray()
                    );
                }
            ),
            map(messages =>
                    messages
                        .reduce(
                            (builder, message) =>
                                builder.withValueForLanguage(
                                    message.key.languageId,
                                    message.value
                                ),
                            MultilingualString.newBuilder()
                        )
                        .build()
            )
        );
    }
}

const fromStoreMetaFieldId = function (storeMetaFieldId: StoreMetaFieldId): MetaFieldId {
    return new MetaFieldId(new MetaCategoryId(storeMetaFieldId.metaCategoryId.systemName),
                           storeMetaFieldId.systemName,
                           new MetaGroupId(storeMetaFieldId.groupId.systemName))

};

const fromStoreCompoundSystemName = function (storeMetaField: StoreMetaField): string | undefined {

    if (!isNil(storeMetaField.lookupMetaCategoryId))
        return storeMetaField.lookupMetaCategoryId.systemName;

    if (!isNil(storeMetaField.classifierMetaCategoryId))
        return storeMetaField.classifierMetaCategoryId.systemName;

    if (!isNil(storeMetaField.documentMetaCategoryId))
        return storeMetaField.documentMetaCategoryId.systemName;

    if (!isNil(storeMetaField.mainEntityCategoryId))
        return storeMetaField.mainEntityCategoryId.systemName;

    if (!isNil(storeMetaField.workflowStateMetaCategoryId))
        return storeMetaField.workflowStateMetaCategoryId.systemName;

    if (!isNil(storeMetaField.parentMetaCategoryId))
        return storeMetaField.parentMetaCategoryId.systemName;

    if (!isNil(storeMetaField.subEntityMetaCategoryId))
        return storeMetaField.subEntityMetaCategoryId.systemName;

};

const fromStoreMetaField = function (storeMetaField: StoreMetaField): MetaField {
    const typeHelper = new MetaFieldTypeHelper();

    return new MetaField(fromStoreMetaFieldId(storeMetaField.metaFieldId), storeMetaField.displayName,
                         typeHelper.getMetaFieldType(storeMetaField.metaFieldType),
                         storeMetaField.metaFieldType, storeMetaField.isMultiline,
                         fromStoreCompoundSystemName(storeMetaField), storeMetaField.maxLength,
                         storeMetaField.fieldPrefix,
                         storeMetaField.isSystemField, storeMetaField[ENTITY_MULTILINGUAL_NAME_ATTRIBUTE],
                         storeMetaField.isActionDataField, storeMetaField.isTransient)
};


const fromStoreMetaCategory = function (storeMetaCategory: StoreMetaCategory): MetaCategory {
    return new MetaCategory(new MetaCategoryId(storeMetaCategory.metaCategoryId.systemName),
                            storeMetaCategory.displayName, storeMetaCategory.hasCustomSorting,
                            storeMetaCategory.hasIcon, undefined, Number(storeMetaCategory.wfProcessId),
                            storeMetaCategory.isCacheable,
                            !isNil(storeMetaCategory.nameMetaFieldIds) ?
                            storeMetaCategory.nameMetaFieldIds.map(
                                (item: StoreMetaFieldId) => fromStoreMetaFieldId(item)).valueSeq().toArray() :
                            undefined,
                            storeMetaCategory.isWithVersioning, storeMetaCategory.isClassifier,
                            !isNil(storeMetaCategory.keyMetaFieldIds) ?
                            storeMetaCategory.keyMetaFieldIds.map(
                                (item: StoreMetaFieldId) => fromStoreMetaFieldId(item)).valueSeq().toArray() :
                            undefined,
                            !isNil(storeMetaCategory.actionDataMetaFieldIds) ?
                            storeMetaCategory.actionDataMetaFieldIds.map(
                                (item: StoreMetaFieldId) => fromStoreMetaFieldId(item)).valueSeq().toArray() :
                            undefined,
                            !isNil(storeMetaCategory.transientMetaFieldIds) ?
                            storeMetaCategory.transientMetaFieldIds.map(
                                (item: StoreMetaFieldId) => fromStoreMetaFieldId(item)).valueSeq().toArray() :
                            undefined,
                            storeMetaCategory[ENTITY_MULTILINGUAL_NAME_ATTRIBUTE],
                            storeMetaCategory.showInCategoryManager,
                            isNil(storeMetaCategory.searchableFieldIds) ?
                            undefined :
                            storeMetaCategory.searchableFieldIds.map(
                                (item: StoreMetaFieldId) => fromStoreMetaFieldId(
                                    item)).toArray())

};
