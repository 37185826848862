import {Observable} from "rxjs/Observable";
import {MetaCategory} from "../model/meta-category";
import {MetaField} from "../model/meta-field";
import {MetaGroup} from "../model/meta-group";
import {MetaFieldId} from "../model/meta-field-id";
import {MetaCategoryId} from "../model/meta-category-id";
import {HierarchicalMetaCategoryId} from "../model/hierarchical-meta-category-id";

/**
 * Abstract Class Interface responsible for getting main KB data including all categories, categoryFields and etc.
 * @author Anania.Mikaelyan
 */
export abstract class KbService {

    /**
     * Gets Observable of set of all system category described in KB
     * @param customPrefix prefix for kb type (example reporting, audit)
     */
    public abstract getAllMetaCategories(customPrefix ?: string): Observable<Set<MetaCategory>>;

    /**
     * Gets Observable of set of Classifier system category described in KB
     * @param customPrefix prefix for kb type (example reporting, audit)
     */
    public abstract getClassifierMetaCategories(customPrefix ?: string): Observable<Set<MetaCategory>>;

    /**
     * Gets Observable of set of classifiers that must be visible/modifiable in category manager system category described in KB
     * @param customPrefix prefix for kb type (example reporting, audit)
     */
    public abstract getCategoryManagerMetaCategories(customPrefix ?: string): Observable<Set<MetaCategory>>;

    /**
     * Gets Observable of set of MainEntity/Master system category described in KB
     * @param customPrefix prefix for kb type (example reporting, audit)
     */
    public abstract getMainEntityMetaCategories(customPrefix ?: string): Observable<Set<MetaCategory>>;

    /**
     * Gets MetaCategory by passed MetaCategoryId
     * @param metaCategoryId
     * @param customPrefix prefix for kb type (example reporting, audit)
     */
    public abstract getMetaCategoryByMetaCategoryId(metaCategoryId: MetaCategoryId, customPrefix ?: string): Observable<MetaCategory>;

    /**
     * Gets Observable of all MetaField by passed MetaFieldId
     * @param metaFieldId
     * @param customPrefix prefix for kb type (example reporting, audit, me)
     */
    public abstract getMetaFieldByMetaFieldId(metaFieldId: MetaFieldId, customPrefix ?: string): Observable<MetaField>;

    /**
     * Gets Observable of array of all MetaFields for passed category
     * @param categorySystemName the system name of requested category
     * @param customPrefix prefix for kb type (example reporting, audit, me)
     */
    public abstract getMetaFields(categorySystemName: string, customPrefix ?: string): Observable<Array<MetaField>> ;


    /**
     * Gets Observable of array of NON system MetaFields for passed category
     * @param categorySystemName the system name of requested category
     * @param customPrefix prefix for kb type (example reporting, audit)
     */
    public abstract getNonSystemMetaFields(categorySystemName: string, customPrefix ?: string): Observable<Array<MetaField>>;


    /**
     * Gets Observable of array of Main Entity inner MetaFields for passed category and field
     * @param categorySystemName the system name of requested category
     * @param mainEntityMetaFieldSystemName
     * @param customPrefix prefix for kb type (example reporting, audit)
     */
    public abstract getMainEntityMetaFields(categorySystemName: string, mainEntityMetaFieldSystemName: string, customPrefix ?: string): Observable<Array<MetaField>>;

    /**
     * Gets Observable of array of 'name' MetaFieldIds for passed category.
     * 'display-name' MetaFields are the MetaFields by which categories are displayed in not details modes (table item name, select-box item label, category title)
     * @param categorySystemName the system name of requested category
     * @param customPrefix prefix for kb type (example reporting, audit, me)
     */
    public abstract getNameMetaFieldIds(categorySystemName: string, customPrefix ?: string): Observable<Array<MetaFieldId>>;

    /**
     * Get Observable of array of 'key' MetaFieldIds for passed category
     *
     * @param categorySystemName the system name of requested category
     * @param customPrefix  prefix for kb type (example: reporting, audit, me)
     */
    public abstract getKeyMetaFieldIds(categorySystemName: string, customPrefix ?: string): Observable<Array<MetaFieldId>>

    /**
     * Extracts metaFields form given json
     * @param metaFieldIdJson
     */
    public abstract extractMetaFieldId(metaFieldIdJson: any): MetaFieldId

    /**
     * Gets Observable of array of 'classifier-view' MetaFields.
     */
    public abstract getClassifierViewMetaFields(): Observable<Array<MetaField>>


    /**
     * Gets category ALL MetaGroup-field  for given category
     * @param categorySystemName the system name of category which MetaGroup hierarchy that must be returned
     * @param customPrefix prefix for kb type (example reporting, audit, me)
     */
    public abstract getAllMetaGroups(categorySystemName: string, customPrefix ?: string): Observable<MetaGroup[]>;

    /**
     * Gets category MetaGroup-field hierarchy for given category, excluding system-group
     * @param categorySystemName the system name of category which MetaGroup hierarchy that must be returned
     * @param customPrefix prefix for kb type (example reporting, audit)
     */
    public abstract getNonSystemMetaGroups(categorySystemName: string, customPrefix ?: string): Observable<MetaGroup[]>;

    /**
     * Gets Observable of array of 'HierarchicalMetaCategoryId'(all trees of meta-categories that have children).
     */
    public abstract getHierarchicalMetaCategoryIds(customPrefix ?: string): Observable<Array<HierarchicalMetaCategoryId>>


    /**
     * Gets Observable of array of Ancestors 'MetaCategoryId' for passed MetaCategoryId(all trees of meta-categories that have children).
     * Example for Location5 method will return [Location4, Location3, Location2, Location1]
     * @param metaCategoryId Ancestors of this metaCategoryId
     * @param customPrefix prefix for kb type (example reporting, audit)
     */
    public abstract getAncestorsMetaCategoryIds(metaCategoryId : MetaCategoryId, customPrefix ?: string) : Observable<Array<MetaCategoryId>>

    /**
     * Gets category Identity-field of given category.
     * If category doesn't have Identity-field method throws error
     * @param categorySystemName the system name of category which identity must be returned
     * @param customPrefix prefix for kb type (example reporting, audit)
     */
    public abstract getIdentityMetaField(categorySystemName: string, customPrefix ?: string): Observable<MetaField>;

    /**
     * Gets category Instance-field of given category.
     * If category doesn't have Instance-field method returns null instead
     * @param categorySystemName the system name of category which instance must be returned
     * @param customPrefix prefix for kb type (example reporting, audit)
     */
    public abstract getInstanceMetaField(categorySystemName: string, customPrefix ?: string): Observable<MetaField>

    /**
     * Gets category WorkflowState-field of given category.
     * If category doesn't have WorkflowState-field method returns null instead
     * @param categorySystemName the system name of category which workflow state must be returned
     * @param customPrefix prefix for kb type (example reporting, audit)
     */
    public abstract getWorkflowStateMetaField(categorySystemName: string, customPrefix ?: string): Observable<MetaField>


    /**
     * return workflow action field id (without group name)
     * @param metaCategoryId categoryId which workflow state must be returned
     * @param customPrefix prefix for kb type
     */
    public abstract getWorkflowActionMetaFieldId(metaCategoryId: MetaCategoryId,
                                                 customPrefix?: string): MetaFieldId
    
    /**
     * @deprecated use instead getAllMetaCategories
     * Gets Observable of set of all system category described in KB
     */
    public abstract getCategories(): Observable<Set<MetaCategory>>;
    /**
     * Gets Observable of array of 'display-name' MetaFields for passed category.
     * 'display-name' MetaFields are the MetaFields by which categories are displayed in not details modes (table item name, select-box item label, category title)
     * @param categorySystemName the system name of requested category
     * @Deprecated use getNameMetaFieldIds
     */
    public abstract getDisplayNameMetaFields(categorySystemName: string): Observable<Array<MetaField>>;
    /**
     * Gets category MetaGroup-field given category, excluding system-group
     * @param categorySystemName the system name of category which MetaGroup hierarchy that must be returned
     * @deprecated use instead getNonSystemMetaGroups
     */
    public abstract getMetaGroups(categorySystemName: string): Observable<MetaGroup[]>;

    /**
     * Resets in-memory cache
     */
    public abstract resetCache(): void;



}
