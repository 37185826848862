import {MetaFieldType} from "../model/meta-field";
import {Injectable} from "@angular/core";

/**
 * Created by anania.mikaelyan on 11/30/16.
 */

@Injectable()
export class MetaFieldTypeHelper {
    private MetaFieldTypes: Map<string, MetaFieldType> = new Map<string, MetaFieldType>();

    constructor() {
        this.MetaFieldTypes.set("INTEGER_IDENTITY", MetaFieldType.INTEGER_IDENTITY);
        this.MetaFieldTypes.set("INTEGER", MetaFieldType.INTEGER);
        this.MetaFieldTypes.set("DOUBLE", MetaFieldType.DECIMAL);
        this.MetaFieldTypes.set("BIG_DECIMAL", MetaFieldType.BIG_DECIMAL);
        this.MetaFieldTypes.set("INTEGER_INSTANCE", MetaFieldType.INTEGER_INSTANCE);
        this.MetaFieldTypes.set("STRING", MetaFieldType.STRING);
        this.MetaFieldTypes.set("MULTILINGUAL_STRING", MetaFieldType.MULTILINGUAL_STRING);
        this.MetaFieldTypes.set("DATE", MetaFieldType.DATE);
        this.MetaFieldTypes.set("DATE_TIME", MetaFieldType.DATE_TIME);
        this.MetaFieldTypes.set("BOOLEAN", MetaFieldType.BOOLEAN);
        this.MetaFieldTypes.set("CLASSIFIER", MetaFieldType.CLASSIFIER);
        this.MetaFieldTypes.set("DOCUMENT", MetaFieldType.DOCUMENT);
        this.MetaFieldTypes.set("PARENT", MetaFieldType.PARENT);
        this.MetaFieldTypes.set("MULTI_SELECT", MetaFieldType.MULTI_SELECT);
        this.MetaFieldTypes.set("SUB_ENTITY", MetaFieldType.SUB_ENTITY);
        this.MetaFieldTypes.set("MAIN_ENTITY", MetaFieldType.MAIN_ENTITY);
        this.MetaFieldTypes.set("WORKFLOW_STATE", MetaFieldType.WORKFLOW_STATE);
        this.MetaFieldTypes.set("USER", MetaFieldType.USER);
        this.MetaFieldTypes.set("LOCAL_DATE", MetaFieldType.LOCAL_DATE);
        this.MetaFieldTypes.set("LOCAL_DATE_TIME", MetaFieldType.LOCAL_DATE_TIME);
        this.MetaFieldTypes.set("MONEY", MetaFieldType.MONEY);
        this.MetaFieldTypes.set("ACCOUNTING", MetaFieldType.ACCOUNTING);
        this.MetaFieldTypes.set("LOOKUP", MetaFieldType.LOOKUP)

    }

    public getMetaFieldType(MetaFieldTypeStr: string): MetaFieldType {
        return this.MetaFieldTypes.get(MetaFieldTypeStr);
    }
}
