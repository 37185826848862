/**
 * @author Vahagn Lazyan.
 * @since 1.0.0
 */
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

@Injectable()
export class FormResolverService {
    /**
     * Used for navigating to data entry form.
     * @type {string}
     * @default
     * @static
     * @readonly
     * @private
     */
    private static readonly TO_DATA_ENTRY_ROUTE: string = 'dynamic-de';

    constructor(protected router: Router) {}

    /**
     * Navigates to create new dynamic data-entry form.
     *
     * Returns a promise that:
     * - resolves to 'true' when navigation succeeds,
     * - resolves to 'false' when navigation fails,
     * - is rejected when an error happens.
     *
     * @param {string} categorySystemName
     * @param {string} formName
     * @param {number} [formId=0]
     * @param {object} queryParams
     * @returns {Promise<boolean>}
     */
    public navigateToCreateNewDeForm(
        categorySystemName: string,
        formName: string,
        formId?: number,
        queryParams?: object
    ): Promise<boolean> {
        if (!formId) {
            formId = 0;
        }
        return this.navigateToDynamicDeForm(
            categorySystemName,
            formName,
            formId,
            undefined,
            queryParams,
            ''
        );
    }

    /**
     * Navigates to editable dynamic data-entry form.
     *
     * Returns a promise that:
     * - resolves to 'true' when navigation succeeds,
     * - resolves to 'false' when navigation fails,
     * - is rejected when an error happens.
     *
     * @param {string} categorySystemName
     * @param {string} formName
     * @param {number} instanceId
     * @param {number} [formId=0]
     * @param {string} tabId
     * @param {object} queryParams
     * @returns {Promise<boolean>}
     */
    public navigateToEditDeForm(
        categorySystemName: string,
        formName: string,
        instanceId: number,
        formId?: number,
        queryParams?: object,
        tabId?: string
    ): Promise<boolean> {
        if (!formId) {
            formId = 0;
        }
        return this.navigateToDynamicDeForm(
            categorySystemName,
            formName,
            formId,
            instanceId,
            queryParams,
            tabId
        );
    }

    /**
     * Navigates to dynamic view data-entry form.
     *
     * Returns a promise that:
     * - resolves to 'true' when navigation succeeds,
     * - resolves to 'false' when navigation fails,
     * - is rejected when an error happens.
     *
     * @param {string} categorySystemName
     * @param {string} formName
     * @param {number} instanceId
     * @param {number} [formId=0]
     * @param {string} tabId
     * @param {object} queryParams
     * @returns {Promise<boolean>}
     */
    public navigateToViewDeForm(
        categorySystemName: string,
        formName: string,
        instanceId: number,
        formId?: number,
        queryParams?: object,
        tabId?: string
    ): Promise<boolean> {
        if (!formId) {
            formId = 0;
        }
        return this.navigateToDynamicDeForm(
            categorySystemName,
            formName,
            formId,
            instanceId,
            queryParams,
            tabId
        );
    }

    /**
     * Creates navigation url for create new dynamic de forms.
     * @param {string} categorySystemName
     * @param {string} formName
     * @param {number} [formId=0]
     * @param {object} queryParams
     * @returns {Promise<string>}
     */
    public getUrlForCreateNewDeForm(
        categorySystemName: string,
        formName: string,
        formId?: number,
        queryParams?: object
    ): Promise<string> {
        if (!formId) {
            formId = 0;
        }
        return this.getUrlForDynamicForm(
            categorySystemName,
            formName,
            formId,
            undefined,
            queryParams
        );
    }

    /**
     * Creates navigation url for edit dynamic de forms.
     * @param {string} categorySystemName
     * @param {string} formName
     * @param {number} instanceId
     * @param {number} [formId=0]
     * @param {object} queryParams
     * @returns {Promise<string>}
     */
    public getUrlForEditDeForm(
        categorySystemName: string,
        formName: string,
        instanceId: number,
        formId?: number,
        queryParams?: object
    ): Promise<string> {
        if (!formId) {
            formId = 0;
        }
        return this.getUrlForDynamicForm(
            categorySystemName,
            formName,
            formId,
            instanceId,
            queryParams
        );
    }

    /**
     * Creates navigation url for view dynamic de forms.
     * @param {string} categorySystemName
     * @param {string} formName
     * @param {number} instanceId
     * @param {number} [formId=0]
     * @param {object} queryParams
     * @returns {Promise<string>}
     */
    public getUrlForViewDeForm(
        categorySystemName: string,
        formName: string,
        instanceId: number,
        formId?: number,
        queryParams?: object
    ): Promise<string> {
        if (!formId) {
            formId = 0;
        }
        return this.getUrlForDynamicForm(
            categorySystemName,
            formName,
            formId,
            instanceId,
            queryParams
        );
    }

    public getUrlForCreateNewDeFormSync(
        categorySystemName: string,
        formName: string,
        formId?: number,
        queryParams?: object
    ): string {
        if (!formId) {
            formId = 0;
        }
        return this.getUrlForDynamicFormSync(
            categorySystemName,
            formName,
            formId,
            undefined,
            queryParams
        );
    }

    public getUrlForEditDeFormSync(
        categorySystemName: string,
        formName: string,
        instanceId: number,
        formId?: number,
        queryParams?: object
    ): string {
        if (!formId) {
            formId = 0;
        }
        return this.getUrlForDynamicFormSync(
            categorySystemName,
            formName,
            formId,
            instanceId,
            queryParams
        );
    }

    public getUrlForViewDeFormSync(
        categorySystemName: string,
        formName: string,
        instanceId: number,
        formId?: number,
        queryParams?: object
    ): string {
        if (!formId) {
            formId = 0;
        }
        return this.getUrlForDynamicFormSync(
            categorySystemName,
            formName,
            formId,
            instanceId,
            queryParams
        );
    }

    protected navigateToDynamicDeForm(
        categorySystemName: string,
        formName: string,
        formId: number,
        instanceId?: number,
        queryParams?: object,
        tabId?: string
    ): Promise<boolean> {
        return !instanceId
            ? this.router.navigate(
                  [
                      FormResolverService.TO_DATA_ENTRY_ROUTE,
                      categorySystemName,
                      formName,
                      formId,
                  ],
                  {
                      queryParams: queryParams,
                      fragment: tabId,
                  }
              )
            : this.router.navigate(
                  [
                      FormResolverService.TO_DATA_ENTRY_ROUTE,
                      categorySystemName,
                      formName,
                      formId,
                      instanceId,
                  ],
                  {
                      queryParams: queryParams,
                      fragment: tabId,
                  }
              );
    }

    protected getUrlForDynamicForm(
        categorySystemName: string,
        formName: string,
        formId: number,
        instanceId?: number,
        queryParams?: object
    ): Promise<string> {
        return Promise.resolve(
            this.getUrlForDynamicFormSync(
                categorySystemName,
                formName,
                formId,
                instanceId,
                queryParams
            )
        );
    }

    protected getUrlForDynamicFormSync(
        categorySystemName: string,
        formName: string,
        formId: number,
        instanceId?: number,
        queryParams?: object
    ): string {
        let result = instanceId
            ? `${FormResolverService.TO_DATA_ENTRY_ROUTE}/${categorySystemName}/${formName}/${formId}/${instanceId}`
            : `${FormResolverService.TO_DATA_ENTRY_ROUTE}/${categorySystemName}/${formName}/${formId}`;
        if (queryParams) {
            const params: string[] = [];
            for (const param in queryParams) {
                if (queryParams.hasOwnProperty(param)) {
                    params.push(`${param}=${queryParams[param]}`);
                }
            }
            result = `${result}?${params.join('&')}`;
        }
        return result;
    }
}
