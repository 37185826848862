import {Injectable} from "@angular/core";
import {ApplicationPropertiesService} from "@synisys/idm-application-properties-service-client-js";
import {CategoryType} from "./kb-deserialization-helper";
import {KbHttpService} from "./kb-http.service";
import {MetaCategory} from "../model/meta-category";
import {HttpClientWrapper} from "@synisys/idm-authentication-client-js"
import {Store} from "@ngrx/store";
import {KbState} from "@synisys/skynet-store-kb-api";

@Injectable()
export class KbHttpOfflineService extends KbHttpService {

    constructor(http: HttpClientWrapper,
                store: Store<KbState>,
                applicationPropertiesService: ApplicationPropertiesService) {
        super(http, store, applicationPropertiesService);
    }

    protected extractMetaCategories(responseData: any, categoryType: CategoryType): Set<MetaCategory> {
        return this.kbDeserializationHelper.extractCategories(responseData, categoryType, true);
    }

}