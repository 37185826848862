/**
 * Created by Anania, Anushavan on 12/7/2016.
 */
import {Injectable} from '@angular/core';
import {MultilingualString} from '@synisys/idm-crosscutting-concepts-frontend';
import {isNil} from 'lodash';
import 'rxjs/add/observable/combineLatest';
import 'rxjs/add/operator/publishReplay';
import {Observable} from 'rxjs/Observable';
import {HierarchicalMetaCategoryId} from '../model/hierarchical-meta-category-id';
import {MetaCategory} from '../model/meta-category';
import {MetaCategoryId} from '../model/meta-category-id';
import {MetaCategoryOffline} from '../model/meta-category-offline';
import {MetaField, MetaFieldType} from '../model/meta-field';
import {MetaFieldId} from '../model/meta-field-id';
import {MetaGroup} from '../model/meta-group';
import {MetaGroupId} from '../model/meta-group-id';
import {MetaFieldTypeHelper} from './meta-field-type-helper';


@Injectable()
export class KbDeserializationHelper {

    private SYSTEM_GROUP_SYSTEM_NAME: string = 'systemGroup';

    public extractCategories(responseData: any, categoryType: CategoryType,
                             extractOfflineFeatures?: boolean): Set<MetaCategory> {
        let categories: any;
        switch (categoryType) {
            case CategoryType.MAIN_ENTITY: {
                categories = responseData.filter(
                    (item: MetaCategory) => item.getIsWithVersioning());
                break;
            }
            case CategoryType.CLASSIFIER: {
                categories = responseData.filter(
                    (item: MetaCategory) => item.getIsClassifier());
                break;
            }
            case CategoryType.CATEGORY_MANAGER: {
                categories = responseData.filter((item: MetaCategory) => isNil(
                    item.getShowInCategoryManager()) ? item.getIsClassifier() :
                                                                         item.getShowInCategoryManager());
                break;
            }
            default: {
                categories = responseData;
            }
        }

        return categories.sort(this.systemNameComparator);

    }

    public extractHierarchicalCategories(responseData: any): Array<HierarchicalMetaCategoryId> {
        let categories: Array<HierarchicalMetaCategoryId> = [];
        responseData.forEach((category: any) => {
            categories.push(this.extractHierarchicalCategory(category));
        });
        return categories;
    }

    public extractHierarchicalCategory(categoryItem: any): HierarchicalMetaCategoryId {
        let children: any = [];
        categoryItem.child.forEach((child: any) => {
            children.push(this.extractHierarchicalCategory(child));
        });
        return new HierarchicalMetaCategoryId(categoryItem.data.systemName,
                                              categoryItem.isRoot, children);
    }

    public extractGroups(metaFields$: Observable<Array<MetaField>>,
                         categoryGroups: any,
                         excludeSystemMetaGroup: boolean): Observable<MetaGroup[]> {
        return metaFields$
            .map(allMetaFields => {
                let displayName: string;
                let metaFields: Array<MetaField>;
                let metaGroups: Array<MetaGroup> = [];
                // let categoryGroups: Array<any> = data[1].body ? data[1].body : data[1];

                categoryGroups.forEach((categoryGroup: any) => {
                    if (!excludeSystemMetaGroup || categoryGroup.systemName !== this.SYSTEM_GROUP_SYSTEM_NAME) {
                        metaFields = [];
                        let systemName = categoryGroup.systemName;
                        let displayNameMultilingual = categoryGroup.displayNameMultilingual;
                        displayName = categoryGroup.displayName ?
                                      categoryGroup.displayName : '';
                        categoryGroup.metaFieldIds.forEach((item: any) => {
                            allMetaFields.forEach((metaField: MetaField) => {
                                if (metaField.getSystemName() === item.systemName) {
                                    metaFields.push(metaField);
                                }
                            });
                        });
                        metaGroups.push(
                            new MetaGroup(systemName, displayName, metaFields,
                                          displayNameMultilingual));
                    }
                });
                return metaGroups;
            })
            .catch(this.handleError);
    }

    public extractFields(categorySystemName: string,
                         response: any): Array<MetaField> {
        return response.map((item: any) => {
            return this.extractField(categorySystemName, item);
        });
    }

    public extractMetaFieldId(metaFieldIdJson: any): MetaFieldId {
        const name: string = metaFieldIdJson.systemName;
        const categorySystemName: string = metaFieldIdJson.metaCategoryId.systemName;
        const groupSystemName: string = metaFieldIdJson.groupId != null ?
                                        metaFieldIdJson.groupId.systemName :
                                        null;
        return new MetaFieldId(new MetaCategoryId(categorySystemName), name,
                               new MetaGroupId(groupSystemName));
    }

    public extractCategory(item: any,
                           extractOfflineFeatures?: boolean): MetaCategory {
        const categorySystemName: string = item.metaCategoryId.systemName;
        const displayNameMsgId: string = item.displayName;
        const hasCustomCategory: boolean = item.hasCustomSorting;
        const isCacheable: boolean = item.isCacheable;
        const metaCategoryId: MetaCategoryId = new MetaCategoryId(
            categorySystemName);
        const wfProcessId: number = item.wfProcessId;
        const hasIcon: boolean = item.hasIcon ? item.hasIcon : false;
        const isWithVersioning: boolean = item.isWithVersioning ?
                                          item.isWithVersioning : false;
        const isClassifier: boolean = item.isClassifier ? item.isClassifier :
                                      false;


        const nameMetaFieldIds: Array<MetaFieldId> = item.nameMetaFieldIds.map(
            (metaFieldIdJson: any) => {
                return this.extractMetaFieldId(metaFieldIdJson);
            });
        const keyMetaFieldIds: Array<MetaFieldId> = item.keyMetaFieldIds.map(
            (metaFieldIdJson: any) => {
                return this.extractMetaFieldId(metaFieldIdJson);
            });
        const actionDataMetaFieldIds: Array<MetaFieldId> = item.actionDataMetaFieldIds
                                                           ?
                                                           item.actionDataMetaFieldIds.map(
                                                               (metaFieldIdJson: any) => {
                                                                   return this.extractMetaFieldId(
                                                                       metaFieldIdJson)
                                                               })
                                                           : [];
        if (!extractOfflineFeatures) {
            return new MetaCategory(metaCategoryId, displayNameMsgId,
                                    hasCustomCategory, hasIcon,
                                    '', wfProcessId, isCacheable,
                                    nameMetaFieldIds, isWithVersioning,
                                    isClassifier, keyMetaFieldIds,
                                    actionDataMetaFieldIds,);
        }

        const identityMetaFieldId: MetaFieldId = this.extractMetaFieldId(
            item.identityMetaFieldId);
        const instanceMetaFieldId: MetaFieldId = item.instanceMetaFieldId ?
                                                 this.extractMetaFieldId(
                                                     item.instanceMetaFieldId) :
                                                 null;
        const isSearchable: boolean = item.isSearchable;
        const searchableMetaFieldIds: Array<MetaFieldId> = [];
        item.searchableFieldIds.forEach((metaFieldIdJson: any) => {
            searchableMetaFieldIds.push(
                this.extractMetaFieldId(metaFieldIdJson))
        });

        return new MetaCategoryOffline(metaCategoryId, displayNameMsgId,
                                       hasCustomCategory,
                                       identityMetaFieldId, instanceMetaFieldId,
                                       isSearchable, searchableMetaFieldIds,
                                       false, '', wfProcessId, isCacheable,
                                       isWithVersioning,
                                       isClassifier, nameMetaFieldIds,
                                       keyMetaFieldIds, actionDataMetaFieldIds);
    }

    public extractField(categorySystemName: string, item: any): MetaField {
        let metaFieldTypeHelper: MetaFieldTypeHelper = new MetaFieldTypeHelper();
        const name: string = item.metaFieldId.systemName;
        const metaFieldId: MetaFieldId = this.extractMetaFieldId(
            item.metaFieldId);
        const isMultiline: boolean = item.isMultiline;
        const maxLength: number = item.maxLength;
        const displayName: string = item.displayName ? item.displayName : name;
        const type: MetaFieldType = metaFieldTypeHelper.getMetaFieldType(
            item.metaFieldType);
        const typeStr: string = item.metaFieldType;
        const fieldPrefix: string = item.fieldPrefix;
        const compoundCategorySystemName: string = this.isCompoundField(type) ?
                                                   this.extractCategorySystemName(
                                                       item, type) : null;
        const displayNameMultilingual: MultilingualString | undefined = item.displayNameMultilingual;

        return new MetaField(metaFieldId, displayName, type, typeStr,
                             isMultiline,
                             compoundCategorySystemName, maxLength, fieldPrefix,
                             item.isSystemField, displayNameMultilingual,
                             item.isActionDataField);
    }

    private extractCategorySystemName(item: any, type: MetaFieldType): string {
        switch (type) {
            case MetaFieldType.PARENT: {
                return item.parentMetaCategoryId.systemName;
            }
            case MetaFieldType.CLASSIFIER:
            case MetaFieldType.MULTI_SELECT: {
                return item.classifierMetaCategoryId.systemName;
            }
            case MetaFieldType.SUB_ENTITY: {
                return item.subEntityMetaCategoryId.systemName;
            }
            case MetaFieldType.MAIN_ENTITY: {
                return item.mainEntityCategoryId.systemName;
            }
            case MetaFieldType.WORKFLOW_STATE: {
                return item.workflowStateMetaCategoryId.systemName;
            }
            case MetaFieldType.LOOKUP: {
                return item.lookupMetaCategoryId.systemName;
            }
            default: {
                throw new Error(`Unsupported compound field type ${type}`);
            }
        }
    }

    private systemNameComparator(item1: any, item2: any): number {
        let systemName1: string = item1.metaCategoryId.systemName.toLowerCase();
        let systemName2: string = item2.metaCategoryId.systemName.toLowerCase();

        return systemName1 < systemName2 ? -1 :
               systemName1 > systemName2 ? 1 : 0;
    }

    private handleError(error: any) {
        let errMsg = (error.message) ? error.message :
                     error.status ? `${error.status} - ${error.statusText}` :
                     'Server error';
        console.error(errMsg);

        return Observable.throw(errMsg);
    }

    private isCompoundField(fieldType: MetaFieldType): boolean {
        return fieldType === MetaFieldType.CLASSIFIER || fieldType === MetaFieldType.MULTI_SELECT
               || fieldType === MetaFieldType.MAIN_ENTITY || fieldType === MetaFieldType.SUB_ENTITY
               || fieldType === MetaFieldType.WORKFLOW_STATE || fieldType === MetaFieldType.PARENT
               || fieldType === MetaFieldType.LOOKUP;
    }


}

export enum CategoryType {
    ALL,
    MAIN_ENTITY,
    CLASSIFIER,
    CATEGORY_MANAGER
}
