import {MetaCategoryId} from "./meta-category-id";
import {MetaCategory} from "./meta-category";
import {MetaFieldId} from "./meta-field-id";

export class MetaCategoryOffline extends MetaCategory {

    constructor(metaCategoryId: MetaCategoryId,
                displayNameMsgId: string,
                hasCustomSorting: boolean,
                private identityMetaFieldId: MetaFieldId,
                private instanceMetaFieldId: MetaFieldId,
                private isSearchable: boolean,
                private searchableMetaFieldIds: Array<MetaFieldId>,
                hasIcon?: boolean,
                defaultIconPath?: string,
                wfProcessId?: number,
                isCacheable?: boolean,
                isWithVersioning?: boolean,
                isClassifier?: boolean,
                nameMetaFieldIds?: Array<MetaFieldId>,
                keyMetaFieldIds?: Array<MetaFieldId>,
                actionDataMetaFieldIds?: Array<MetaFieldId>) {
        super(metaCategoryId, displayNameMsgId, hasCustomSorting, hasIcon,
            defaultIconPath, wfProcessId, isCacheable, nameMetaFieldIds,
            isWithVersioning, isClassifier, keyMetaFieldIds, actionDataMetaFieldIds);
    }

    public getIdentityMetaFieldId(): MetaFieldId {
        return this.identityMetaFieldId;
    }

    public getInstanceMetaFieldId(): MetaFieldId {
        return this.instanceMetaFieldId;
    }

    public getIsSearchable(): boolean {
        return this.isSearchable;
    }

    public getSearchableMetaFieldIds(): Array<MetaFieldId> {
        return this.searchableMetaFieldIds;
    }
}
