import {MetaFieldId} from "./meta-field-id";
import {MultilingualString} from "@synisys/idm-crosscutting-concepts-frontend";

export class MetaField {

    constructor(private metaFieldId: MetaFieldId,
                private displayNameMsgId: string, //need for MetaCategory Manager
                private type: MetaFieldType,
                private metaFieldTypeStr: string,
                private isMultiline: boolean,//just for debugging
                private compoundCategorySystemName?: string,
                private maxLength?: number,
                private fieldPrefix?: string,//if type is compound the system name of compound category
                private isSystemField?: boolean,
                private displayNameMultilingual?: MultilingualString,
                private isActionDataField?: boolean,
                private isTransient?: boolean) {
    }

    public getSystemName() {
        return this.metaFieldId.getSystemName();
    }

    public getMaxLength(): number {
        return this.maxLength;
    }

    public getFieldPrefix(): string {
        return this.fieldPrefix;
    }

    public setFieldPrefix(value: string) {
        this.fieldPrefix = value;
    }

    public getMetaFieldId(): MetaFieldId {
        return this.metaFieldId;
    }

    public getDisplayNameMsgId(): string {
        return this.displayNameMsgId;
    }

    public getType(): MetaFieldType {
        return this.type;
    }

    public getCompoundCategorySystemName(): string {
        return this.compoundCategorySystemName;
    }

    public getIsMultiline(): boolean {
        return this.isMultiline;
    }

    public getIsSystemField (): boolean {
        return this.isSystemField;
    }

    public getIsTransient(): boolean {
        return this.isTransient
    }

    public getDisplayNameMultilingual (): MultilingualString {
        return this.displayNameMultilingual;
    }

    public getIsActionDataField(): boolean {
        return this.isActionDataField;
    }

    public setIsActionDataField(value: boolean) {
        this.isActionDataField = value;
    }

    private toString(): string {
        return "(" + this.metaFieldId.getSystemName() + ","
            + this.displayNameMsgId + ","
            + this.type + ","
            + this.metaFieldTypeStr + ","
            + this.isMultiline +
            +this.compoundCategorySystemName + ")"
    }
}

export enum MetaFieldType {
    INTEGER_IDENTITY,
    INTEGER,
    STRING,
    MULTILINGUAL_STRING,
    DATE,
    DATE_TIME,
    BOOLEAN,
    CLASSIFIER,
    PARENT,
    MULTI_SELECT,
    SUB_ENTITY,
    MAIN_ENTITY,
    DECIMAL,
    BIG_DECIMAL,
    INTEGER_INSTANCE,
    DOCUMENT,
    MULTILINESTRING,
    WORKFLOW_STATE,
    USER,
    LOCAL_DATE,
    LOCAL_DATE_TIME,
    MONEY,
    ACCOUNTING,
    LOOKUP
}

//TODO: I would suggest to separate MetaField and MetaFieldType in separate files.
//Also the types of the field and return types of the methods are not mentioned.
//The convention for model files is that "model" should be written in the file name e.g "meta-field.model.ts" MM
