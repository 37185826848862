/**
 * Created by Astghik.Hakobyan on 1/17/2018.
 */

export class HierarchicalMetaCategoryId {

    constructor(private systemName: string,
                private isRoot: boolean,
                private children: Array<HierarchicalMetaCategoryId>) {
    }

    public getSystemName(): string {
        return this.systemName;
    }

    public getIsRoot(): boolean {
        return this.isRoot;
    }

    public getChildren(): Array<HierarchicalMetaCategoryId> {
        return this.children;
    }

}